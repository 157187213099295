<template>
  <v-list two-line>
    <v-list-item
      v-for="item in items.filter((e) => e !== undefined)"
      :key="item.id"
      @click.stop="$emit('contentClick', item)"
    >
      <v-row no-gutters>
        <v-col cols="8" md="4">
          {{ formatName(item) }}
          <v-icon
            v-if="!item.hasICE || item.hasOutdatedICE"
            color="warning"
            style="margin-top: -4px"
            small
            >mdi-alert</v-icon
          >
        </v-col>
        <v-col cols="4">
          <span
            v-for="label in item.labels"
            :key="item.id + label"
            class="v-chip v-chip--label theme--light px-2 mr-1"
          >
            {{ label }}
          </span>
        </v-col>
        <v-col cols="12" class="text-body-1 text--secondary">
          {{ item.zip_code }} {{ item.city }}
          <span v-if="item.city && item.zip_code && item.phone">/ </span>
          <phone-link>{{ item.phone }}</phone-link>
        </v-col>
      </v-row>
      <v-list-item-action class="list-tile-action-multiple">
        <v-btn
          icon
          @click.stop="$emit('removeitem', item)"
          :disabled="disabled"
        >
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { useUserStore } from '@/stores/user'

export default {
  name: 'EventParticipationList',
  components: {},
  props: {
    items: {
      required: true,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    formatName(person) {
      if (useUserStore().settings.nameDisplay === 'first-last') {
        return `${person.first_name} ${person.last_name}`.trim()
      } else {
        return `${person.last_name} ${person.first_name}`.trim()
      }
    },
  },
}
</script>
