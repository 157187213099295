import { defineStore } from 'pinia'
import personService from '@/services/person'
import Vue from 'vue'


function setICEStatus(person) {
  const oneYearAgo = new Date()
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
  person.hasICE = (!!person.ice_name && !!person.ice_phone)
  if(person.hasICE) {
    person.hasOutdatedICE = (new Date(person.ice_last_modified)) < oneYearAgo
  }
  return person
}


export const usePersonStore = defineStore('person', {
  state: () => ({
    loaded: false,
    persons: {},
  }),
  getters: {
    personList: (state) => {
      return Object.values(state.persons)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    memberList: (state) => {
      return Object.values(state.persons)
        .filter(person => person.type === 'member' && !person.deleted)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    numMembers: (state) => {
      return state.memberList.length
    },
    numDeletedMembers: (state) => {
      return Object.values(state.persons)
        .filter(person => person.type === 'member' && person.deleted)
        .length
    },
    guestList: (state) => {
      return Object.values(state.persons)
        .filter(person => person.type === 'guest' && !person.deleted)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    numGuests: (state) => {
      return state.guestList.length
    },
    numDeletedGuests: (state) => {
      return Object.values(state.persons)
        .filter(person => person.type === 'guest' && person.deleted)
        .length
    },
    getById: (state) => (id) => {
      return state.persons[id]
    },

  },
  actions: {
    fetchPersons() {
      if(this.loaded) { return Promise.resolve() }

      return personService.list()
        .then((persons) => {
          const newPersons = {}
          for(const p of persons) {
            setICEStatus(p)
            newPersons[p.id] = p
          }
          this.persons = newPersons
          this.loaded = true
        })
    },
    getPerson(id) {
      return personService.get(id)
        .then((person) => {
          setICEStatus(person)
          Vue.set(this.persons, person.id, person)
          return person
        })
    },
    updateOrCreate(person) {
      const serviceCall = person.id === undefined ? personService.create : personService.update
      return serviceCall(person)
        .then(person => {
          setICEStatus(person)
          Vue.set(this.persons, person.id, person)
          return person
        })
    },
    deletePerson(person) {
      return personService.remove(person)
        .then(() => {
          Vue.delete(this.persons, person.id)
        })
    },
    updateICE(person) {
      return personService.updateICE(person).then(person => {
        setICEStatus(person)
        Vue.set(this.persons, person.id, person)
        return person
      })
    },
  },
})
