<template>
  <v-card ref="componentRoot" min-height="500">
    <v-toolbar
      flat
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); flex-grow: 0"
      :height="isFullscreen ? 60 : 90"
      :extension-height="isFullscreen ? 120 : 0"
      max-height="190"
    >
      <v-btn
        large
        icon
        v-if="closeable && isFullscreen"
        @click.stop="$emit('close')"
        class="grey--text text--darken-3"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <!-- Larger Screens -->
      <text-icon v-if="!isFullscreen" colorize class="xlarge">{{
        person.name
      }}</text-icon>
      <v-row v-if="!isFullscreen" class="ml-4">
        <div class="text-h5">
          {{ `${person.first_name} ${person.last_name}` }}
        </div>
        <div class="ml-2 pt-1">
          <v-chip small disabled text-color="grey darken-4">{{
            person.type == "member" ? "Mitglied" : "Gast"
          }}</v-chip>
        </div>
      </v-row>

      <v-spacer />
      <v-btn
        large
        icon
        v-if="editable"
        @click.stop="$emit('edit')"
        class="grey--text text--darken-3"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        large
        icon
        v-if="closeable && !isFullscreen"
        @click.stop="$emit('close')"
        class="grey--text text--darken-3"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <!-- Small Screens -->
      <div
        v-if="isFullscreen"
        slot="extension"
        class="d-flex flex-column align-center flex-grow-1"
      >
        <div class="mb-2" style="margin-top: -40px">
          <text-icon colorize class="xlarge">{{ person.name }}</text-icon>
        </div>
        <div class="text-h6 mb-2">{{ person.name }}</div>
        <v-chip small disabled text-color="grey darken-3">{{
          person.type == "member" ? "Mitglied" : "Gast"
        }}</v-chip>
      </div>
    </v-toolbar>
    <v-tabs v-model="activeTab" grow class="tabdecoration">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="item in tabItems" :key="item.id">
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-card-text class="pt-2">
      <v-tabs-items v-model="activeTab" class="mx-n4">
        <!-- mx-n4: Add negative margin to get more space for the event tabel -->
        <!-- Kontaktdaten -->
        <v-tab-item class="mx-4">
          <!-- mx-4: Revert the negative spacing for the contact info -->
          <v-row dense>
            <v-col cols="12" class="subheading mb-2 font-weight-medium"
              >Kontaktinformationen</v-col
            >
            <v-col cols="3" md="2" class="mb-1 text-center" v-if="hasAddress">
              <v-icon>mdi-home</v-icon>
            </v-col>
            <v-col cols="9" md="10" v-if="hasAddress">
              {{ person.address }}<br />
              {{ person.zip_code }} {{ person.city }}
            </v-col>

            <v-col cols="3" md="2" class="mb-1 text-center" v-if="person.email">
              <v-icon>mdi-email</v-icon>
            </v-col>
            <v-col cols="9" md="10" v-if="person.email">
              <mail-link>{{ person.email }}</mail-link>
            </v-col>
            <v-col cols="3" md="2" class="mb-1 text-center" v-if="person.phone">
              <v-icon>mdi-phone</v-icon>
            </v-col>
            <v-col cols="9" md="10" v-if="person.phone">
              <phone-link>{{ person.phone }}</phone-link>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center"
              v-if="person.date_of_birth"
            >
              <v-icon>mdi-cake-variant</v-icon>
            </v-col>
            <v-col cols="9" md="10" v-if="person.date_of_birth"
              >{{ person.date_of_birth }} ({{ age }})</v-col
            >
          </v-row>

          <v-row dense>
            <v-col cols="12" class="subheading mb-1 mt-3 font-weight-medium">Notfallkontakt</v-col>
            <template v-if="hasEmergencyContact">
              <v-col cols="3" md="2" class="text-center">
                <v-icon>mdi-phone</v-icon>
              </v-col>
              <v-col cols="9" md="10">
                {{ person.ice_name }}
                {{ person.ice_relation && ` (${person.ice_relation})` }}
              </v-col>
              <v-col cols="3" md="2" class="mb-1"> </v-col>
              <v-col cols="9" md="10">
                <phone-link>{{ person.ice_phone }}</phone-link>
              </v-col>
              <v-col cols="3" md="2" class="mb-1"> </v-col>
              <v-col cols="9" md="10">
                Letzte Aktualisierung: {{ (new Date(storePerson.ice_last_modified)).toLocaleDateString() }}
                <v-icon
                  v-if="storePerson.hasOutdatedICE"
                  color="warning"
                  style="margin-top: -4px"
                  small
                  >mdi-alert</v-icon
                >
              </v-col>
              <v-col cols="3" md="2" class="mb-1"> </v-col>
              <v-col cols="9" md="10">
                <v-btn small @click="confirmICEContact">Notfallkontakt besätigen</v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="3" md="2" class="text-center">
                <v-icon color="warning">mdi-account-alert</v-icon>
              </v-col>
              <v-col cols="9" md="10"> Kein Notfallkontakt vorhanden! </v-col>
            </template>
          </v-row>
          <div class="subheading mb-1 mt-3 font-weight-medium">Bemerkungen</div>
          <v-row>
            <v-col cols="11" offset="1">
              {{ person.notes }}
            </v-col>
            <v-col cols="12" v-if="person.deleted">
              <v-divider />
              <div class="red--text text--lighten-1 mt-1">
                Diese Person ist in der Datenbank als gelöscht markiert.
              </div>
              <div>
                Bei Vereinsmitglieder ist dies automatisch der Fall wenn sie den
                SAC verlassen, Gäste können explizit gelöscht werden.
              </div>
            </v-col>
          </v-row>

          <div class="subheading mb-1 mt-3 font-weight-medium">J+S Daten</div>

          <v-row dense>
              <v-col cols="12" sm="4" md="3" class="text-left text-sm-right">
                J+S Personennummer:
              </v-col>
              <v-col cols="11" sm="8" md="9" offset="1" offset-sm="0">
                {{ person.js_number || "---" }}
              </v-col>

              <v-col cols="12" sm="4" md="3" class="text-left text-sm-right">
                AHV - Nummer / PEID:
              </v-col>
              <v-col cols="11" sm="8" md="9" offset="1" offset-sm="0">
                {{ person.js_ahv_number || "---" }} / {{ person.js_peid || "---" }}
              </v-col>

          </v-row>
        </v-tab-item>

        <!-- Touren -->
        <v-tab-item>
          <template v-if="canViewParticipatedEvents">
            <v-data-table
              :headers="eventTableHeaders"
              :items="events"
              :items-per-page="10"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 25, 50],
              }"
              :loading="eventsLoading"
            >
              <template v-slot:item.organisation="{ item }">
                <org-icon :organisation="item.organisation"></org-icon>
              </template>
            </v-data-table>
          </template>
          <v-card-text v-else>
            Aktuell sind nur Tourenchefs dazu berechtigt, die Touren, welche ein
            Mitglied / Gast besucht hat einzusehen. Falls du diese Informationen
            benötigst, melde dich beim Tourenchef deiner Tourengruppe.
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import personService from '@/services/person'
import eventService from '@/services/event'
import { dateCHtoISO } from '@/utils/dates'
import { usePersonStore } from '@/stores/person'
import { mapState } from 'pinia'

export default {
  name: 'PersonDetailCard',
  components: {},
  props: {
    person: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      tabItems: [
        { id: 'contact', title: 'Kontakt' },
        { id: 'events', title: 'Touren' },
        // { id: 'qualification', title: 'Ausbildung' },
      ],
      eventTableHeaders: [
        { text: 'Gruppe', value: 'organisation', sortable: false, width: 70 },
        { text: 'Tour', value: 'title', sortable: true },
        { text: 'Datum', value: 'date', sortable: false },
        { text: 'Schwierigkeit', value: 'difficulty', sortable: false },
        { text: 'Rolle', value: 'role', sortable: false },
      ],
      events: [],
      eventsLoading: false,
      eventsLoadedFor: undefined,
    }
  },
  computed: {
    hasEmergencyContact() {
      return this.person.ice_name && this.person.ice_phone
    },
    hasAddress() {
      return this.person.address || this.person.zip_code || this.person.city
    },
    isFullscreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
    age() {
      if (!this.person.date_of_birth) {
        return ''
      }
      const [day, month, year] = this.person.date_of_birth.split('.')
      const today = new Date()
      let age = today.getFullYear() - year
      const m = (today.getMonth() + 1) - month // getMonth() returns a zero based value 0 = January
      if (m < 0 || (m === 0 && today.getDate() < day)) {
        age--
      }
      return age
    },
    canViewParticipatedEvents() {
      return this.$can('view', 'Person', 'participatedEvents')
    },
    ...mapState(usePersonStore, {
      storePerson(store) {
        return store.getById(this.person.id)
      },
    },
    ),
  },
  methods: {
    confirmICEContact() {
      const store = usePersonStore()
      store.updateICE(this.person)
    },
    loadEvents(person) {
      if (!this.canViewParticipatedEvents) {
        return
      }
      if (this.person.id === this.eventsLoadedFor) {
        return
      }
      this.eventsLoading = true
      this.events = []
      const eventPromises = []
      const fetchedEventIds = []
      personService.getEvents(person).then(events => {
        for (const e of events.guided) {
          fetchedEventIds.push(e)
          eventPromises.push(eventService.get(e))
        }
        for (const e of events.participated) {
          if (fetchedEventIds.includes(e)) {
            continue
          }
          eventPromises.push(eventService.get(e))
        }
        Promise.all(eventPromises).then(allEvents => {
          this.events = allEvents.map(e => {
            const id = person.id
            return {
              ...e,
              role: (id === e.main_guide ? 'Hauptleiter' : e.guides.includes(id) ? 'Leiter' : 'Teilnehmer'),
              isoDate: dateCHtoISO(e.start_date),
            }
          }).sort((a, b) => (a.isoDate < b.isoDate) ? -1 : ((a.isoDate > b.isoDate) ? 1 : 0)).reverse()
          this.eventsLoading = false
          this.eventsLoadedFor = person.id
        })
      })
    },
  },
  watch: {
    person(_newPerson) {
      // If the person changes, switch back to the first tab
      this.activeTab = 0
    },
    activeTab(newTab) {
      if (newTab === 1) {
        this.loadEvents(this.person)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tabdecoration {
  max-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
</style>
