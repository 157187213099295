<template>
  <v-container fluid>
    <v-progress-linear v-if="loading" :indeterminate="true" />

    <v-alert
      type="warning"
      colored-border
      border="bottom"
      :value="hasMissingICE || hasOutdatedICE"
      class="my-3 elevation-1"
    >
      Für einige Teilnehmer / Leiter dieser Tour ist kein Notfallkontakt
      hinterlegt oder der Kontakt wurde seit über einem Jahr nicht mehr aktualisiert. Bitte ergänze / aktualisiere diese Daten bis zum Tourbeginn.
    </v-alert>

    <v-alert
      type="warning"
      colored-border
      border="bottom"
      :value="!!event.alternative_title"
      class="my-3 elevation-1"
    >
      Für diese Tour wurde eine Ausweichtour festgelegt!<br />
      <b>Neues Ziel:</b> {{ event.alternative_title }}<br />
      <b>Beschreibung:</b>
      {{ event.alternative_description || "Nicht angegeben" }}
    </v-alert>

    <v-alert
      type="warning"
      colored-border
      border="bottom"
      :value="event.status === 'canceled'"
      class="my-3 elevation-1"
    >
      Diese Tour wurde abgesagt!<br />
      <b>Begründung:</b> {{ report || "Nicht angegeben" }}
    </v-alert>

    <v-alert
      type="warning"
      colored-border
      border="bottom"
      :value="
        event.fully_booked &&
        !(totalNumberOfParticipants >= event.max_participants)
      "
      class="my-3 elevation-1"
    >
      Diese Tour ist als <b>ausgebucht</b> markiert.
    </v-alert>

    <v-alert
      type="warning"
      colored-border
      border="bottom"
      :value="event.max_participants && totalNumberOfParticipants >= event.max_participants"
      class="my-3 elevation-1"
    >
      <div>
        Für diese Tour sind
        <b>{{ totalNumberOfParticipants }} Teilnehmer angemeldet</b>. Die
        <b>maximale Teilnehmerzahl</b> wurde mit<b>
          {{ event.max_participants }}</b
        >
        angegeben. Aktuell ist die Tour als
        <b>{{ event.fully_booked ? "ausgebucht" : "nicht ausgebucht" }}</b>
        markiert.
      </div>
      <v-btn
        tile
        elevation="0"
        large
        outlined
        class="mr-1 my-2"
        @click="markEventBooked"
      >
        <v-icon left>mdi-account-off</v-icon
        >{{
          event.fully_booked ? "Ausgebucht entfernen" : "Ausgebucht markieren"
        }}
      </v-btn>
    </v-alert>

    <!-- Basic Info -->
    <v-card class="mb-3">
      <v-card-title class="align-baseline">
        <div class="flex-grow-0 text-h6 mr-2">
          <event-title :event="event" text-size="text-h6"></event-title>
        </div>
        <div class="text-subtitle-1 grey--text text--darken-2">
          {{ event.date }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="4" sm="2" class="text-subtitle-2">Hauptleiter:</v-col>
          <v-col cols="8" sm="4" lg="2">{{
            event.main_guide | mapIDtoPerson
          }}</v-col>
          <v-col cols="4" sm="2" class="text-subtitle-2">Tourart:</v-col>
          <v-col cols="8" sm="4" lg="2">{{ event.kind | mapKind }}</v-col>
          <v-col cols="4" sm="2" class="text-subtitle-2 text-truncate"
            >Schwierigkeit:</v-col
          >
          <v-col cols="8" sm="4" lg="2">{{ event.difficulty }}</v-col>
          <v-col cols="4" sm="2" class="text-subtitle-2">Teilnehmer:</v-col>
          <v-col cols="8" sm="4" lg="2">
            {{ totalNumberOfParticipants }}
            {{ event.max_participants && `(Max. ${event.max_participants})` }}
          </v-col>
          <v-col cols="4" sm="2" class="text-subtitle-2 text-truncate"
            >Tourengruppen:</v-col
          >
          <v-col cols="8" sm="4" lg="2">
            <ul class="csvlist ul-first">
              <li
                v-for="org in [
                  event.organisation,
                  ...(event.allowed_organisations || []),
                ]"
                :key="org"
              >
                {{ org | mapOrg }}
              </li>
            </ul>
          </v-col>
          <v-col
            cols="4"
            sm="2"
            class="text-body-2 text-truncate"
            v-if="event.external_planing"
            >Tourenplanung:
          </v-col>
          <v-col
            cols="8"
            sm="4"
            lg="2"
            v-if="event.external_planing"
            class="text-no-wrap text-truncate"
          >
            <a :href="event.external_planing" target="_blank">{{
              event.external_planing
            }}</a>
          </v-col>

          <!-- Placeholder -->
          <v-col cols="12" class="my-1"></v-col>
          <template v-if="isMobile">
            <v-col>
              <v-btn
                :href="event.upload"
                :disabled="!event.upload"
                target="_blank"
                tile
                elevation="0"
                large
                outlined
                class="mr-1 mb-1"
              >
                <v-icon left>mdi-download</v-icon>Tourenblatt
              </v-btn>
              <v-bottom-sheet v-model="bottomSheet">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    tile
                    elevation="0"
                    large
                    outlined
                    class="mr-1 mb-1"
                    >Aktionen</v-btn
                  >
                </template>
                <v-sheet class="text-center pb-6">
                  <v-btn
                    class="mt-6"
                    text
                    color="error"
                    @click="bottomSheet = !bottomSheet"
                  >
                    schliessen
                  </v-btn>
                  <div class="d-flex flex-column mt-4 mx-8 mx-sm-14">
                    <event-action-button
                      v-for="(item, index) in actions.slice(1)"
                      :key="index"
                      v-bind="item"
                      class="mb-2"
                    ></event-action-button>
                  </div>
                </v-sheet>
              </v-bottom-sheet>
            </v-col>
          </template>

          <template v-else>
            <v-col cols="12">
              <event-action-button
                v-for="(item, index) in actions"
                :key="index"
                class="mr-1 mb-1"
                v-bind="item"
              ></event-action-button>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-expansion-panels v-if="!loading" v-model="panelState" multiple>
      <!-- Leiter & Teilnehmer -->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6">
          Leiter &amp; Teilnehmer
        </v-expansion-panel-header>
        <v-expansion-panel-content class="panel-no-padding">
          <toolbar
            title="Leiter"
            :source="availablePersons"
            @itemclick="addGuide"
            :disabled="$ability.cannot('update', 'Event', 'guides')"
          />
          <list
            :items="guides"
            @removeitem="(item) => removeGuide(item.id)"
            @contentClick="(item) => showDetail(item)"
            :disabled="$ability.cannot('update', 'Event', 'guides')"
          />
          <toolbar
            title="Teilnehmer"
            :source="availablePersons"
            @itemclick="addParticipant"
            style="border-radius: 0"
            :disabled="$ability.cannot('update', 'Event', 'participants')"
          />
          <list
            v-if="participants.length > 0"
            :items="participants"
            @removeitem="(item) => removeParticipant(item)"
            @contentClick="(item) => showDetail(item)"
            :disabled="$ability.cannot('update', 'Event', 'participants')"
          />
          <v-card-text v-else>Keine Teilnehmer</v-card-text>
          <v-card-text v-if="!loading">
            Teilnehmer nicht gefunden? Erfasse
            <a @click="newGuest">einen neuen Gast</a>.
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Notizen -->
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6"
          >Notizen</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <p>
            Hier erfasste Notizen sind nicht öffentlich zugänglich. Gibt es
            etwas wichtiges was du auf der Tour nicht vergessen darfst? Schreib
            es hier auf!
          </p>
          <v-textarea
            v-model="event.notes"
            @blur="saveEvent"
            multi-line
            rows="10"
            hide-details
            solo
            :disabled="$ability.cannot('update', 'Event', 'notes')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6"
          >Tourenbericht</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <event-report-form
            :event-id="event.id"
            :disabled="$ability.cannot('update', 'Event', 'report')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Fotos -->
      <!-- <v-expansion-panel>
        <v-expansion-panel-header class="text-h6">Fotos</v-expansion-panel-header>
        <v-expansion-panel-content>
          <photo-upload :event-id="event.id">
            <template v-slot:images>
              <v-col sm="3" xl="2" v-for="photo in photos" :key="photo">
                <image-preview
                  :src="`/media/${photo}?thumb=500x500&t=${(new Date()).getTime()}`"
                  @remove="deleteImage(photo)"
                />
              </v-col>
            </template>
          </photo-upload>
        </v-expansion-panel-content>
      </v-expansion-panel> -->
    </v-expansion-panels>

    <!-- Dialog for Member/Guest Detail & Editing -->
    <person-detail-dialog
      v-model="showDetailDialog"
      :person="dialogItem"
      @edit="showEdit(dialogItem)"
    />

    <person-edit-dialog
      v-model="showEditDialog"
      :person="dialogItem"
      @save="savePerson"
    />

    <v-dialog
      scrollable
      v-model="showMainGuideSelection"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="showMainGuideSelection = false"
    >
      <v-card>
        <v-card-title>Hauptleiter ändern</v-card-title>
        <v-card-text>
          <div class="py-2">
            Der Hauptleiter kann nicht gelöscht, sondern nur geändert werden.
            <br />Wähle den neuen Hauptleiter:
          </div>
          <person-autocomplete
            v-model="mainGuide"
            :persons="allPersons"
            :input-props="{ outlined: true, solo: true, flat: true }"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click.stop="
              showMainGuideSelection = false;
              mainGuide = null;
            "
            >Abbrechen</v-btn
          >
          <v-spacer />
          <v-btn
            color="success"
            @click.stop="
              showMainGuideSelection = false;
              changeMainGuide(newMainGuide);
            "
            >Übernehmen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="showAlternativeEventDialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="showAlternativeEventDialog = false"
    >
      <v-card>
        <v-card-title>Ausweichtour</v-card-title>
        <v-card-text>
          <div class="py-2 mb-2">
            Kann die Tour aufgrund der Verhältnisse nicht durchgeführt werden?
            Erfasse hier die Ausweichtour. Beachte, dass die Ausweichtour nicht
            anspruchsvoller als die geplante Tour sein darf. Am besten sprichst
            du dich mit dem Tourenchef kurz ab!
          </div>
          <v-text-field
            label="Titel"
            v-model="event.alternative_title"
            outlined
          />
          <v-textarea
            label="Beschreibung"
            v-model="event.alternative_description"
            multi-line
            outlined
            rows="3"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click.stop="
              showAlternativeEventDialog = false;
              fetchEvent();
            "
            >Abbrechen</v-btn
          >
          <v-spacer />
          <v-btn
            color="success"
            @click.stop="
              showAlternativeEventDialog = false;
              saveEvent();
            "
            >Übernehmen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapState } from 'pinia'

import Toolbar from '@/components/events/EventParticipationToolbar.vue'
import List from '@/components/events/EventParticipationList.vue'
import EventReportForm from '@/components/events/EventReportForm.vue'
import PersonAutocomplete from '@/components/person/PersonAutocomplete'
import PersonDetailDialog from '@/components/person/PersonDetailDialog.vue'
import PersonEditDialog from '@/components/person/PersonEditDialog.vue'

import downloadService from '@/services/download'
import eventService from '@/services/event'
import { handleApiError, createNotification } from '@/utils.js'
import EventTitle from '@/components/events/EventTitle.vue'
import { usePersonStore } from '@/stores/person'
import { useUserStore } from '@/stores/user'
import { useEventStore } from '@/stores/event'

import EventActionButton from '@/components/events/buttons/EventActionButton.vue'

const EMPTY_GUEST = {
  id: undefined,
  type: 'guest',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  ice_name: '',
  ice_relation: '',
  ice_phone: '',
}

export default {
  name: 'EventView',
  components: {
    Toolbar,
    List,
    PersonDetailDialog,
    PersonAutocomplete,
    PersonEditDialog,
    EventReportForm,
    EventTitle,
    EventActionButton,
  },
  props: {},
  data() {
    return {
      event: {
        id: 0,
        title: '',
        main_guide: null,
        guides: [],
        participants: [],
        max_participants: null,
        notes: '',
      },
      actions: [
        {
          title: 'Tourenblatt',
          icon: 'mdi-download',
          target: '_blank',
          href: () => this.event.upload,
          disabled: () => !this.event.upload,
        },
        {
          title: 'Begleitzettel',
          icon: 'mdi-download',
          click: () => this.downloadExcel(),
        },
        {
          title: 'Bearbeiten',
          icon: 'mdi-pencil',
          disabled: () => this.$ability.cannot('edit', 'Event'),
          to: () => {
            return { name: 'EventEdit', params: this.event.id }
          },
        },
        {
          title: 'Ausweichtour',
          icon: 'mdi-directions-fork',
          disabled: () => this.$ability.cannot('edit', 'Event'),
          click: () => {
            this.showAlternativeEventDialog = true
          },
        },
        {
          title: () =>
            this.event.fully_booked
              ? 'Ausgebucht entfernen'
              : 'Ausgebucht markieren',
          icon: 'mdi-account-off',
          click: () => {
            this.markEventBooked()
          },
        },
        {
          title: 'Fotos',
          icon: 'mdi-image',
          to: () => {
            return { name: 'EventPhotos', params: { id: this.event.id } }
          },
        },
      ],
      photos: [],
      eventLoaded: true,
      report: '',
      showDetailDialog: false,
      showEditDialog: false,
      showMainGuideSelection: false,
      showAlternativeEventDialog: false,
      dialogItem: {},
      isNewGuest: false,
      panelState: [0, 1, 2],
      newMainGuide: null,
      bottomSheet: false,
    }
  },
  computed: {
    loading() {
      const pstore = usePersonStore()
      return !this.eventLoaded || !pstore.loaded
    },
    guideIds() {
      return [this.event.main_guide, ...this.event.guides]
    },
    mainGuide: {
      get() {
        return usePersonStore().getById(this.event.main_guide)
      },
      set(newVal) {
        this.newMainGuide = newVal ? newVal.id : null
      },
    },
    guides() {
      return this.guideIds.map((id) => {
        const p = usePersonStore().getById(id)
        if (!p) return p

        const labels = []
        if (p.type === 'guest') {
          labels.push('Gast')
        }
        p.labels = labels
        return p
      })
    },
    participants() {
      const currentYear = new Date().getFullYear()
      const participants = this.event.participants.map((id) => {
        const p = usePersonStore().getById(id)
        if (!p) return p

        // Add labels
        const labels = []
        if (p.type === 'guest') {
          labels.push('Gast')
        }
        if (
          (this.event.organisation === 'jo' ||
            this.event.organisation === 'kibe') &&
          p.date_of_birth
        ) {
          const yearOfBirth = parseInt(p.date_of_birth.split('.')[2])
          const approxAge = currentYear - yearOfBirth
          if (approxAge >= 10 && approxAge <= 20) {
            labels.push('J+S')
          }
        }
        p.labels = labels
        return p
      })
      if (this.userSettings.participantSorting === 'last-first') {
        return participants.sort((a, b) => {
          const nameA = a.last_name.toUpperCase()
          const nameB = b.last_name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      if (this.userSettings.participantSorting === 'first-last') {
        return participants.sort((a, b) => {
          const nameA = a.first_name.toUpperCase()
          const nameB = b.first_name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      return participants
    },
    hasMissingICE() {
      const persons = [
        ...this.participants,
        ...this.guides,
      ]
      return persons.filter((e) => e !== undefined).some((e) => !e.hasICE)
    },
    hasOutdatedICE() {
      const persons = [
        ...this.participants,
        ...this.guides,
      ]
      return persons.filter((e) => e !== undefined).some((e) => e.hasOutdatedICE)
    },
    allPersons() {
      return this.persons.filter((p) => p !== undefined)
    },
    availablePersons() {
      const usedIds = [
        ...this.guideIds,
        ...this.participants.map((p) => p && p.id),
      ]
      return this.persons.filter((p) => {
        if (p === undefined) return false
        return !(usedIds.indexOf(p.id) > -1)
      })
    },
    totalNumberOfParticipants() {
      return (
        (this.event.participants || []).length +
        (this.event.guests || []).length
      )
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800
    },
    ...mapState(usePersonStore, {
      persons: 'personList',
    }),
    ...mapState(useUserStore, { userSettings: (store) => store.settings }),
  },
  watch: {
    $route: 'fetchEvent',
  },
  created() {
    this.fetchEvent()
  },
  methods: {
    saveEvent() {
      useEventStore()
        .updateEvent(this.event)
        .then((event) => {
          this.event = cloneDeep(event)
          createNotification('Tour gespeichert', 'success')
        })
        .catch((error) => {
          handleApiError(error, 'Fehler beim speichern')
        })
    },
    debouncedSave: debounce(
      function () {
        this.saveEvent()
      },
      1000,
      { maxWait: 5000 },
    ),
    addGuide(member) {
      this.event.guides.push(member.id)
      this.debouncedSave()
    },
    removeGuide(id) {
      const idx = this.event.guides.indexOf(id)
      if (id === this.event.main_guide) {
        this.newMainGuide = this.event.main_guide
        this.showMainGuideSelection = true
        return
      }
      if (idx > -1) {
        this.event.guides.splice(idx, 1)
        this.debouncedSave()
      }
    },
    changeMainGuide(personId) {
      this.event.main_guide = personId
      const idx = this.event.guides.indexOf(personId)
      if (idx > -1) {
        this.event.guides.splice(idx, 1)
      }
      this.saveEvent()
    },
    addParticipant(person) {
      this.event.participants.push(person.id)
      this.debouncedSave()
    },
    removeParticipant(item) {
      const idx = this.event.participants.indexOf(item.id)
      if (idx > -1) {
        this.event.participants.splice(idx, 1)
        this.debouncedSave()
      }
    },
    markEventBooked() {
      this.event.fully_booked = !this.event.fully_booked
      this.saveEvent()
    },
    saveNotes() {
      this.notesDialog = false
      this.event.notes = this.notes
      this.saveEvent()
    },
    showDetail(item) {
      this.showEditDialog = false
      this.showDetailDialog = false
      const promise = usePersonStore().getPerson(item.id)
      promise
        .then((person) => {
          this.showDetailDialog = true
          this.dialogItem = person
        })
        .catch((error) => {
          handleApiError(error, 'Fehler beim Laden der Daten')
        })
    },
    showEdit(item) {
      this.showEditDialog = false
      this.showDetailDialog = false
      this.isNewGuest = false
      const promise = usePersonStore().getPerson(item.id)
      promise
        .then((member) => {
          this.showEditDialog = true
          this.dialogItem = member
        })
        .catch((error) => {
          handleApiError(error, 'Fehler beim Laden der Daten')
        })
    },
    savePerson(person) {
      usePersonStore()
        .updateOrCreate(person)
        .then((person) => {
          createNotification('Daten gespeichert', 'success')
          this.showEditDialog = false
          if (this.isNewGuest) {
            this.isNewGuest = false
            this.addParticipant(person)
          }
        })
        .catch((error) => {
          handleApiError(error, 'Fehler beim speichern')
        })
    },
    newGuest() {
      this.dialogItem = EMPTY_GUEST
      this.showEditDialog = true
      this.isNewGuest = true
    },
    downloadExcel() {
      downloadService
        .download(`/export/event/${this.event.id}/excel`)
        .catch((error) => {
          handleApiError(error, 'Fehler beim Laden der Datei')
        })
    },
    fetchEvent() {
      this.photos = []
      const id = this.$route.params.id
      if (this.$route.name !== 'EventView' || id === undefined) {
        return
      }

      this.eventLoaded = false

      return useEventStore()
        .getEvent(id)
        .then((event) => {
          this.isNewEvent = false
          this.event = cloneDeep(event)
          this.eventLoaded = true
          return this.event
        })
        .then((event) => {
          eventService.getPhotos(event.id).then((photos) => {
            this.photos = photos
          })
          if (event.status === 'canceled') {
            eventService.getReport(event.id).then((reportData) => {
              this.report = reportData.report
            })
          }
        })
        .catch((error) =>
          handleApiError(error, 'Fehler beim Laden der Daten.'),
        )
    },
    deleteImage(image) {
      eventService.removePhoto(this.event.id, image).then((response) => {
        this.photos = this.photos.filter((obj) => obj !== image)
      })
    },
  },
}
</script>

<style lang="scss">
.panel-no-padding > div {
  padding: 0;
}
</style>
